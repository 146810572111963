<template>
  <section class="chart-box" ref="chartBox" @dblclick="handleFullscreen">
    <el-card title="左键双击全屏查看" :style="chartStyle" v-loading="loading">
      <el-row class="row-container">
        <el-col :span="18" class="chart-map">
          <el-row>
            <div class="map-dv">
              <el-col :span="16">
                <chart class="map-container" :options="deviceMap" autoresize />
              </el-col>
              <el-col :span="8">
                <div class="chart-pie dv">
                  <div class="dv-info">监测点总数 {{totalLocale}}</div>
                  <div class="dv-info">实时达标率 {{this.normal}}</div>
                  <div class="dv-info">实时在线率 {{this.online}}</div>
                  <div class="dv-info">PM2.5实时减排量 {{pm25.toFixed(2)}}</div>
                  <div class="dv-info">PM10实时减排量 {{pm10.toFixed(2)}}</div>
                  <div class="dv-info">VOCS实时减排量 {{vocs.toFixed(2)}}</div>
                  <div class="dv-info">油烟实时减排总量 {{(pm25+pm10+vocs).toFixed(2)}}</div>
                </div>
              </el-col>
            </div>
            <el-col :span="24" class="top-bar">
              <el-col :span="6">
                <chart :options="onlineBar" autoresize />
              </el-col>
              <el-col :span="6">
                <chart :options="complianceBar" autoresize />
              </el-col>
              <el-col :span="6">
                <chart :options="deviceBar" autoresize />
              </el-col>
              <el-col :span="6">
                <chart :options="redEmissionsBar" autoresize />
              </el-col>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="5" class="chart-pie">
          <chart :options="devicePie" autoresize />
          <chart :options="enterprisePie" autoresize />
          <chart :options="compliancePie" autoresize />
          <chart :options="contributionPie" autoresize />
        </el-col>
      </el-row>
    </el-card>
  </section>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/map'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/component/tooltip'
import 'echarts/map/js/world'
import chinaMap from '../china.json'
import capital from '../capital.json'
ECharts.registerMap('china', chinaMap)
import { mapState } from 'vuex'
import { getUserInfo, launchFullscreen } from '@/util/index'
import bg from '@/asset/img/bg.png'

const t1 = ['#289ADB', '#CB6052', '#F0B03B', '#1DB699', '#29EF0C', '#8FC2FD']
// const t2 = ['#37A2DA', '#2DBFEB', '#67E0E3', '#9FE5B6', '#FFDB5C', '#FF9F80']
const barWidth = 12
const itemStyle = {
  normal: {
    barBorderRadius: 10,
    color: new ECharts.graphic.LinearGradient(
      0, 0, 1, 0,
      [
        { offset: 0, color: '#032AF4' },
        { offset: 1, color: '#01D0FE' }
      ]
    )
  }
}
export default {
  components: {
    chart: ECharts
  },
  data() {
    return {
      areaMap: {},
      totalLocale: null,
      online: null,
      normal: null,
      excess: null,
      vocs: 0,
      pm10: 0,
      pm25: 0,
      loading: true,
      pid: {},
      customer: {},
      timer: null,
      chartStyle: {
        backgroundImage: 'url(' + bg + ')',
        backgroundRepeat: 'no-repeat',
        // height: '100%',
        // width: '100%',
        backgroundSize: 'cover'
      },
      deviceMap: {
        title: {
          text: '设备分布情况',
          x: 'center',
          y: 'top',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: (p) => {
            return `${p.data.name}: ${p.data.value[2]}台`
          }
        },
        geo: {
          map: 'china',
          // left: 10,
          // top: 0,
          // right: 0,
          // bottom: 0,
          zoom: 1.2,
          label: {
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#323c48',
              borderColor: '#111'
            },
            emphasis: {
              areaColor: '#79FFFF'
            }
          }
        },
        series: [
          {
            name: '在线设备',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: [],
            // symbolSize: val => val[2],
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#f4e925',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            zlevel: 1
          }
        ]
      },
      devicePie: {
        title: {
          text: '安装数量占比',
          x: 'center',
          y: 'top',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {d}%'
        },
        color: t1,
        dataset: {
          source: []
        },
        series: [
          {
            name: '达标占比',
            type: 'pie',
            radius: ['30%', '40%'],
            center: ['50%', '50%'],
            // data: [],
            // label: {
            //   formatter: '{b}: {d}%'
            // },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            encode: {
              itemName: 'N',
              value: 'V'
            }
          }
        ]
      },
      enterprisePie: {
        title: {
          text: '大中小型餐饮企业安装占比',
          x: 'center',
          y: 'top',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}型企业 : {d}%'
        },
        color: t1,
        dataset: {
          source: []
        },
        series: [
          {
            name: '超标占比',
            type: 'pie',
            radius: ['30%', '40%'],
            center: ['50%', '50%'],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            encode: {
              itemName: 'K',
              value: 'V'
            },
            label: {
              normal: {
                formatter: '{b}型企业',
                show: true
              }
            }
          }
        ]
      },
      compliancePie: {
        title: {
          text: '油烟排放达标率',
          x: 'center',
          y: 'top',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: v => {
            return `${this.areaMap[v.name].N} : ${v.percent}%`
          }
        },
        color: t1,
        dataset: {
          source: []
        },
        series: [
          {
            name: '超标占比',
            type: 'pie',
            radius: ['30%', '40%'],
            center: ['50%', '50%'],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            encode: {
              itemName: 'K',
              value: 'V'
            },
            label: {
              normal: {
                formatter: v => {
                  return `${this.areaMap[v.name].N}`
                },
                show: true
              }
            }
          }
        ]
      },
      contributionPie: {
        title: {
          text: '油烟减排贡献率',
          x: 'center',
          y: 'top',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: v => {
            return `${this.areaMap[v.name].N} : ${v.percent}%`
          }
        },
        color: t1,
        dataset: {
          source: []
        },
        series: [
          {
            name: '超标占比',
            type: 'pie',
            radius: ['30%', '40%'],
            center: ['50%', '50%'],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            encode: {
              itemName: 'K',
              value: 'V'
            },
            label: {
              normal: {
                formatter: v => {
                  return `${this.areaMap[v.name].N}`
                },
                show: true
              }
            }
          }
        ]
      },
      onlineBar: {
        title: {
          text: '昨日在线率 TOP5',
          x: 'center',
          y: '12%',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        // legend: {
        //   textStyle: {
        //     color: '#fff'
        //   }
        // },
        tooltip: {
          formatter: v => {
            return `${v.name}: ${v.value.V}%`
          }
        },
        dataset: {
          source: []
        },
        xAxis: {
          // interval: 150,
          // show: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff'
            // formatter: v => {
            //   return this.areaMap[v].N
            // }
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
          // show:false
        },
        grid: {
          containLabel: true
        },
        series: [
          {
            type: 'bar',
            barWidth,
            // stack: 'chart',
            // z: 3,
            itemStyle,
            encode: {
              y: 'N',
              x: 'V'
            }
          }
        ]
      },
      complianceBar: {
        title: {
          text: '昨日达标率 TOP5',
          x: 'center',
          y: '12%',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        // legend: {
        //   textStyle: {
        //     color: '#fff'
        //   }
        // },
        tooltip: {
          formatter: v => {
            return `${v.name}: ${v.value.V}%`
          }
        },
        dataset: {
          source: []
        },
        xAxis: {
          interval: 150,
          // show: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
          // show:false
        },
        grid: {
          containLabel: true
        },
        series: [
          {
            type: 'bar',
            barWidth,
            // stack: 'chart',
            // z: 3,
            itemStyle,
            encode: {
              y: 'N',
              x: 'V'
            }
          }
        ]
      },
      deviceBar: {
        title: {
          text: '安装数 TOP5',
          x: 'center',
          y: '12%',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        // legend: {
        //   textStyle: {
        //     color: '#fff'
        //   }
        // },
        tooltip: {
          // formatter: v => {
          //   return `${this.areaMap[v.name].N}: ${v.value.V}`
          // }
        },
        dataset: {
          source: []
        },
        xAxis: {
          interval: 150,
          // show: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
          // show:false
        },
        grid: {
          containLabel: true
        },
        series: [
          {
            type: 'bar',
            barWidth,
            // stack: 'chart',
            // z: 3,
            itemStyle,
            encode: {
              y: 'N',
              x: 'V'
            }
          }
        ]
      },
      redEmissionsBar: {
        title: {
          text: '减排累计 TOP5',
          x: 'center',
          y: '12%',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        // legend: {
        //   textStyle: {
        //     color: '#fff'
        //   }
        // },
        tooltip: {
          formatter: v => {
            return `${this.areaMap[v.name].N}: ${v.value.V}`
          }
        },
        dataset: {
          source: []
        },
        xAxis: {
          interval: 150,
          // show: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            formatter: v => {
              return this.areaMap[v].N
            }
          },
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['transparent']
              // width: 1,
              // type: 'solid'
            }
          }
          // show:false
        },
        grid: {
          containLabel: true
        },
        series: [
          {
            type: 'bar',
            barWidth,
            // stack: 'chart',
            // z: 3,
            itemStyle,
            encode: {
              // y: 'N',
              x: 'V'
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState(['customerTree', 'locale', 'clientHeight']),
    pageStyle() {
      return {
        height: `${this.clientHeight}px`,
        position: 'relative'
      }
    }
  },
  created() {
    const info = getUserInfo()
    if (info.Info) {
      this.pid = info.Info['pid']
    }
    this.customerTree.forEach(e => {
      this.customer['' + e.Id] = e.Name
    })
    this.getData('1')
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer)
    } else {
      this.timer = setInterval(() => {
        this.getData('0')
      }, 10000)
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    handleFullscreen() {
      launchFullscreen(this.$refs.chartBox)
    },
    async getData(flag) {
      if (flag === '1') {
        this.loading = true
      }
      const customers = Object.assign({}, this.customer)
      const data = await this.$get('admin/listBoardData', { flag })
      this.online = 0
      this.normal = 0
      this.excess = 0
      this.vocs = 0
      this.pm10 = 0
      this.pm25 = 0
      // this.topBar.dataset.dimensions = ['title']
      // this.topBar.series = []
      data.device.forEach(e => {
        this.areaMap[e.K] = { N: e.N, V: e.V }
        // this.topBar.dataset.dimensions.push(e.N)
        // this.topBar.series.push({ type: 'bar' })
      })
      data.enterprise && (this.enterprisePie.dataset.source = data.enterprise)
      if (flag === '1') {
        this.devicePie.dataset.source = data.device.slice(0, 4)
        let other = 0
        data.device.slice(5).forEach(e => {
          other += e.V
        })
        if (other !== 0) {
          this.devicePie.dataset.source.push({ N: '其他', V: other })
        }
        this.deviceBar.dataset.source = this.devicePie.dataset.source
        const onlineBar = []
        data.online.forEach(e => {
          onlineBar.push({ N: this.areaMap[e.K].N, V: (e.V * 100 / this.areaMap[e.K].V).toFixed(2) })
        })
        this.onlineBar.dataset.source = onlineBar
        // this.topBar.dataset.source.push({ title: '在线率', ...tempArr })
        // tempArr = {}
        this.compliancePie.dataset.source = data.compliance
        const complianceBar = []
        data.compliance.forEach(e => {
          complianceBar.push({ N: this.areaMap[e.K].N, V: (e.V * 100 / this.areaMap[e.K].V).toFixed(2) })
        })
        this.complianceBar.dataset.source = complianceBar
        // this.topBar.dataset.source.push({ title: '达标率', ...tempArr })
        // tempArr = {}
        // data.device.slice(0, 4).forEach(e => {
        //   tempArr[e.N] = e.V
        // })
        // this.topBar.dataset.source.push({ title: '安装数', ...tempArr })
        // tempArr = {}
        this.contributionPie.dataset.source = data.redEmissions
        // data.redEmissions.forEach(e => {
        //   tempArr[this.areaMap[e.K].N] = e.V
        // })
        // this.topBar.dataset.source.push({ title: '累计减排', ...tempArr })
        this.redEmissionsBar.dataset.source = data.redEmissions
        // tempArr = {}
        // data.redPm10.forEach(e => {
        //   tempArr[this.areaMap[e.K].N] = e.V
        // })
        // this.topBar.dataset.source.push({ title: 'PM10排放量', ...tempArr })
        // tempArr = {}
        // data.redPm25.forEach(e => {
        //   tempArr[this.areaMap[e.K].N] = e.V
        // })
        // this.topBar.dataset.source.push({ title: 'PM2.5排放量', ...tempArr })
        // tempArr = {}
        // data.redVocs.forEach(e => {
        //   tempArr[this.areaMap[e.K].N] = e.V
        // })
        // this.topBar.dataset.source.push({ title: 'VOCS排放量', ...tempArr })
        // tempArr = {}
        // data.emissions.forEach(e => {
        //   tempArr[this.areaMap[e.K].N] = e.V
        // })
        // this.topBar.dataset.source.push({ title: '油烟排放量', ...tempArr })
        this.loading = false
      }
      this.totalLocale = data.up.length
      const cNormal = { '其他': 0 }
      const cExcess = { '其他': 0 }
      const capitals = {}
      let o
      let count = 0
      data.up.forEach(e => {
        this.vocs += e.Vocs
        this.pm10 += e.Pm10
        this.pm25 += e.Pm25
        if (e.Area !== '' && capital[e.Area]) {
          if (capitals[e.Area]) {
            capitals[e.Area]['value'][2] += 1
          } else {
            capitals[e.Area] = Object.assign({}, capital[e.Area], { value: [...capital[e.Area]['value'], 1] })
          }
        }
        o = e.Owner.replace(this.pid, '').split('/')[1]
        if (e.Status === 'NORMAL') {
          this.normal += 1
          if (e.Owner !== '' && customers[o]) {
            if (cNormal[customers[o]]) {
              cNormal[customers[o]] += 1
            } else {
              cNormal[customers[o]] = 1
            }
          } else {
            cNormal['其他'] += 1
          }
        } else if (e.Status === 'EXCESS') {
          this.excess += 1
          if (e.Owner !== '' && customers[o]) {
            if (cExcess[customers[o]]) {
              cExcess[customers[o]] += 1
            } else {
              cExcess[customers[o]] = 1
            }
          } else {
            cExcess['其他'] += 1
          }
        } else {
          if (e.Status === 'DOWN') {
            count += 1
          }
        }
      })
      this.online = this.normal + this.excess
      count += this.online
      if (count > 0) {
        this.normal = `${(this.normal * 100 / count).toFixed(0)}%`
        this.online = `${(this.online * 100 / count).toFixed(0)}%`
      }
      const arr = []
      for (const k in capitals) {
        arr.push(capitals[k])
      }
      this.deviceMap.series = { data: arr }
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
.chart-box {
  // color: #d4cece;
  // height: 100vw;
  // font-size: 14px
  // margin: 0 auto;
  ::v-deep .el-card__body {
    padding: 10px 0;
    .row-container {
      display: flex;
      justify-content:space-between;
      .map-container,.dv,.top-bar {
        color: #d4cece;
        text-align: center;
        box-sizing: border-box;
        border-style: solid;
        border-color: rgba(72,89,108,0.9);
        border-width: 2px !important;
        border-radius: 10px;
        padding: 5px !important;
      }
      .map-dv {
        display: flex;
        justify-content:space-between;
      }
      .el-col {
        // canvas {}
        .dv {
          // background-color: rgba(144, 147, 153, 0.4);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          // margin-top: 10px;
          // min-height: 400px;
          margin-left: 16px;
          // padding: 5px 0;
          // width: 210px;
          // height: 400px;
          // line-height: 400px;
          // text-align: center;
          user-select: none;
          .dv-info {
            width: 180px;
            height: 35px;
            border-radius: 5px;
            line-height: 35px;
            font-size: 15px;
            background-color: rgba(144, 147, 153, 0.4);
            margin: 10px auto;
          }
        }
      }
      .top-bar {
        // width: 100%;
        margin-top: 10px;
        .echarts {
          width: auto;
          height: 280px;
        }
      }
    }
  }
}
</style>
